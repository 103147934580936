<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.organisationsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="organisationsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="organisations"
            :search="searchString"
            class="elevation-1"
            multi-sort>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.organisations') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.newOrganisation') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>
                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(save)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" hidden md="6" sm="12">
                                <v-text-field v-model="editedItem.id" :label="$t('organisationsPage.id')"></v-text-field>
                              </v-col>
                              <v-col cols=" 12" md="12" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="name">
                                  <v-text-field
                                    v-model="editedItem.name"
                                    name="name"
                                    clearable
                                    counter="200"
                                    :label="$t('organisationsPage.name')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showOrganisationModal(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="2000" bottom>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
            {{ $t('snackbar.close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>

    <v-dialog v-model="deleteOrganisationModal" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('organisationsPage.deleteOrganisation') }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteOrganisationModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('organisationsPage.deleteMessage') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="deleteOrganisation">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { organisationsHeaders } from '@/mixins/data-table-headers';
import { defaultOrganisation } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import {formatDate} from "@/utils/formatDate";

export default {
  name: 'OrganisationsPage',
  components: { ButtonSubmit },
  data: () => ({
    searchString: '',
    dialog: false,
    dialogDelete: false,
    organisations: [],
    formTitle: 'organisationsPage.newItem',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    enableSave: true,
    deleteOrganisationModal: false,
    statusSuccessfullyChanged: false,
    loading: false,
    snackbar: {
      active: false,
      color: '',
      text: '',
    },
  }),
  created() {
    this.editedItem = Object.assign({}, defaultOrganisation);
    this.loadOrganisations();
  },
  computed: {
    organisationsHeaders() {
      return organisationsHeaders(i18n);
    },
  },
  methods: {
    async loadOrganisations() {
      await this.$store.dispatch('organisations/getAllOrganisations').then((res) => {
        this.organisations = res.data.map((item) => {
          return { ...item, created_at: formatDate(item.created_at) };
        });
      });
    },

    editItem(item) {
      this.editedItem = this.organisations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = item.name;
      this.dialog = true;
    },

    async deleteOrganisation() {
      this.loading = true;

      await this.$store
        .dispatch('organisations/deleteOrganisation', this.editedItemDeleted)
        .then(() => {
          this.loadOrganisations();
          this.showSnackbar(i18n.t('snackbar.deletedSuccessfully'), 'green');
          this.closeDeleteOrganisationModal();
        })
        .catch(() => {
          this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createFormData() {
      let formData = new FormData();
      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }
      formData.append('name', this.editedItem.name);
      return formData;
    },

    async save() {
      this.enableSave = false;
      this.loading = true;
      let path = 'saveOrganisation';

      if (this.editedItem.id) {
        path = 'updateOrganisation';
      }
      let formData = this.createFormData();

      await this.$store
        .dispatch('organisations/' + path, formData)
        .then(() => {
          this.loadOrganisations();
          this.showSnackbar(i18n.t('snackbar.savedSuccessfully'), 'green');

          this.close();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.showSnackbar(e, 'red'));
          } else {
            this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultOrganisation);
      });
      this.$refs.form.reset();
      this.formTitle = 'organisationsPage.newItem';
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultOrganisation);
      });
    },

    showSnackbar(text, color) {
      this.snackbar.active = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },

    showOrganisationModal(item) {
      this.editedItemDeleted = item;
      this.deleteOrganisationModal = true;
    },

    closeDeleteOrganisationModal() {
      this.deleteOrganisationModal = false;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style>
.stats {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cell-style {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
